//////////////////////////////////////////////////
// Page wrappers
//////////////////////////////////////////////////

#root {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	min-height: 100%;
}
.wrapper {
	display: flex;
    flex-direction: column;
	flex-grow: 1;
}
.wrapper-inner {
	flex-grow: 1;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	  sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }
  
  code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	  monospace;
  }  