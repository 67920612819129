.face-container {
	position: relative;
	width: 300px;
	height: 300px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.face-image {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.clickable-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	cursor: pointer;
}